// extracted by mini-css-extract-plugin
export var body = "bM_g";
export var button = "bM_b7";
export var close = "bM_F";
export var container = "bM_h";
export var content = "bM_b6";
export var heading = "bM_G";
export var link = "bM_bv";
export var mobileHide = "bM_g5";
export var open = "bM_bf";
export var section = "bM_b";
export var show = "bM_L";
export var title = "bM_f";
import React from 'react'

export default ({ styles, getStyle, field, formValues, handleInputChange }) => (
  <>
    <p className={styles.textLabel}>{field.label}{field.required && ' *'}</p> 
    {field.options.map(option => (
      <div key={option} className={styles.radioOption}>
        <input 
          className={styles.radioInput + ` ${getStyle(field)}`} 
          type='radio' 
          id={option} 
          name={field.label}
          value={option}
          checked={formValues[field.label][option]}
          onChange={handleInputChange}
        />
        <label className={styles.radioLabel} htmlFor={option}>{option}</label>
      </div>
    ))}
  </>
)

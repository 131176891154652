// extracted by mini-css-extract-plugin
export var bottomCircle = "bD_gJ";
export var content = "bD_b6";
export var description = "bD_c3";
export var hide = "bD_C";
export var icon = "bD_by";
export var image = "bD_d";
export var imageContainer = "bD_gN";
export var left = "bD_bV";
export var nextYear = "bD_gM";
export var right = "bD_bW";
export var section = "bD_b";
export var shrink = "bD_gL";
export var title = "bD_f";
export var topCircle = "bD_gH";
export var wave = "bD_f2";
export var waveContainer = "bD_d3";
export var year = "bD_gK";
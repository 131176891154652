// extracted by mini-css-extract-plugin
export var active = "bx_bd";
export var categories = "bx_dk";
export var category = "bx_dm";
export var description = "bx_c3";
export var featured = "bx_dq";
export var icon = "bx_by";
export var input = "bx_cH";
export var mobileVidContainer = "bx_fY";
export var mobileVideo = "bx_fZ";
export var mobileVideoThumbnail = "bx_f0";
export var mobileVideoTitle = "bx_f1";
export var mobileVidsContainer = "bx_fX";
export var moreVideo = "bx_fS";
export var moreVideoContainer = "bx_fR";
export var moreVideoThumbnail = "bx_fT";
export var moreVideoTitle = "bx_fV";
export var moreVideos = "bx_fJ";
export var moreVideosHeader = "bx_fH";
export var moreVideosSlider = "bx_fQ";
export var moreVideosSliderMobile = "bx_fP";
export var scrollArrow = "bx_fL";
export var scrollButton = "bx_fK";
export var scrollLeft = "bx_fM";
export var scrollRight = "bx_fN";
export var search = "bx_cG";
export var section = "bx_b";
export var sectionTitle = "bx_fG";
export var selector = "bx_fW";
export var selectors = "bx_dD";
export var video = "bx_fF";
export var videoContainer = "bx_fD";
export default (field, formValues, checkEmail, checkZip, isObject, checkObject) => {
  if (formValues[field.label]) {
    if (field.label.includes('Email') && checkEmail(formValues[field.label]))
      return true;
    else if (field.label.includes('Phone') && formValues[field.label].length === 10)
      return true;
    else if (field.label.includes('Zip') && checkZip(formValues[field.label]))
      return true;
    else if (isObject(field.label) && checkObject(formValues[field.label]))
      return true;
    else if (!field.label.includes('Email') && !field.label.includes('Phone') && !field.label.includes('Zip') && !isObject(field.label) && formValues[field.label].length > 0)
      return true;
    return false;
  }
  if (!field.required) {
    return true;
  }
  return false;
}
// extracted by mini-css-extract-plugin
export var active = "bg_bd";
export var arrow = "bg_9";
export var button = "bg_b7";
export var buttonMobile = "bg_d2";
export var column = "bg_bt";
export var columns = "bg_bs";
export var container = "bg_h";
export var description = "bg_c3";
export var disclaimer = "bg_cC";
export var disclaimerButton = "bg_dY";
export var disclaimerContainer = "bg_d4";
export var disclaimerText = "bg_d7";
export var fadeBottom = "bg_d6";
export var fadeTop = "bg_d5";
export var featureFooter = "bg_d0";
export var footer = "bg_cY";
export var footerMobile = "bg_d1";
export var heading = "bg_G";
export var highlight = "bg_dZ";
export var icon = "bg_by";
export var iconContainer = "bg_b2";
export var left = "bg_bV";
export var padding = "bg_dj";
export var popular = "bg_dh";
export var prefix = "bg_dX";
export var recommend = "bg_dV";
export var recommended = "bg_dW";
export var right = "bg_bW";
export var section = "bg_b";
export var show = "bg_L";
export var subHeading = "bg_H";
export var suffix = "bg_df";
export var title = "bg_f";
export var waveContainer = "bg_d3";
export var waveOne = "bg_q";
export var waveThree = "bg_cQ";
export var waveTwo = "bg_n";
export var waves = "bg_cP";
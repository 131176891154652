// extracted by mini-css-extract-plugin
export var arrow = "bc_9";
export var body = "bc_g";
export var circle = "bc_dK";
export var customer = "bc_dJ";
export var feedback = "bc_dH";
export var feedbacks = "bc_dF";
export var footer = "bc_cY";
export var footerText = "bc_cL";
export var left = "bc_bV";
export var nameLocation = "bc_dL";
export var padding = "bc_dj";
export var right = "bc_bW";
export var section = "bc_b";
export var selected = "bc_bg";
export var selectors = "bc_dD";
export var subtitle = "bc_cq";
export var text = "bc_cl";
export var title = "bc_f";
export var wrapper = "bc_dG";
// extracted by mini-css-extract-plugin
export var container = "N_h";
export var content = "N_b6";
export var icon = "N_by";
export var iconContainer = "N_b2";
export var icons = "N_bw";
export var image = "N_d";
export var label = "N_cs";
export var section = "N_b";
export var text = "N_cl";
export var title = "N_f";
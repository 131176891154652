// extracted by mini-css-extract-plugin
export var bottomWave = "bt_c2";
export var button = "bt_b7";
export var buttonContainer = "bt_N";
export var buttonFooter = "bt_fv";
export var container = "bt_h";
export var middleWave = "bt_c1";
export var section = "bt_b";
export var subtitle = "bt_cq";
export var textContainer = "bt_b0";
export var title = "bt_f";
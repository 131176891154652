// extracted by mini-css-extract-plugin
export var appStores = "bb_dB";
export var grey = "bb_dC";
export var icon = "bb_by";
export var iconGroup = "bb_dz";
export var image = "bb_d";
export var left = "bb_bV";
export var reverse = "bb_dy";
export var right = "bb_bW";
export var section = "bb_b";
export var textSection = "bb_dx";
// extracted by mini-css-extract-plugin
export var background = "br_z";
export var backgroundContainer = "br_fm";
export var bottomImage = "br_t";
export var bottomImageContainer = "br_fq";
export var buttons = "br_cp";
export var container = "br_h";
export var imagesContainer = "br_fp";
export var middleWave = "br_c1";
export var section = "br_b";
export var text = "br_cl";
export var textContainer = "br_b0";
export var title = "br_f";
export var topImage = "br_m";
export var waveOverlay = "br_fn";
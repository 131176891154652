import React, { useEffect, useState } from 'react'
import { checkEmail, checkValidInputs, checkZip, handleOptionClick, handleChange, setInitValues } from '../functions'
import { CheckBoxes, Dropdown, Radio, Slider, Text, TextArea } from '../components'
import * as styles from './form.module.scss'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { useGetNodes } from '../functions/useGetNodes'

export default ({
  formContext,
  showForm,
  onClose
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectOpen, setSelectOpen] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [form, setForm] = useState(null);
  const [checkInputs, setCheckInputs] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const nodes = useGetNodes()
 
  // add form success message to forestry
  useEffect(() => {
    if (formContext) {
      setInitValues(formContext, nodes, setForm, setFormValues);
    }
  }, [formContext])

  const handleInputChange = e => {
    handleChange(e, setFormValues);
  }

  const handleClose = () => {
    onClose()
    setShowErrorMessage(false);
    setCurrentPage(0);
  }

  const checkIfValid = field => {
    return (
      checkValidInputs(field, formValues, checkEmail, checkZip, isObject, checkObject)
    )
  }

  const isObject = label => {
    return typeof formValues[label] === 'object' && formValues[label] !== null
  }

  const checkObject = object => {
    let hasChecked = false;
    for (let [key, value] of Object.entries(object)) {
      if (value)
        hasChecked = true;
    }
    return hasChecked;
  }

  const getStyle = field => {
    if (checkInputs) {
      if (!checkIfValid(field)) {
        return styles.invalid;
      }
      if (formValues[field.label]) {
        return styles.valid;
      }
    } else if (formValues[field.label] && checkIfValid(field))
      return styles.valid;
  }

  const handleNext = () => {
    setCheckInputs(true);
    let error = false;
    form.childFormsJson.formPages[currentPage].formFields.forEach(field => {
      if (!checkIfValid(field) && field.label !== 'Hidden field') {
        setShowErrorMessage(true);
        error = true;
      }
    })
    if (!error) {
      setShowErrorMessage(false);
      setCurrentPage(page => page + 1);
      setCheckInputs(false);
    }
  }
  
  const handleBack = () => {
    setCurrentPage(page => page - 1);
    setShowErrorMessage(false);
  }

  const handleSubmit = e => {
    e.preventDefault();
    setCheckInputs(true);
    let error = false;
    form.childFormsJson.formPages[currentPage].formFields.forEach(field => {
      if (!checkIfValid(field)) {
        setShowErrorMessage(true);
        error = true;
      }
    })
    if (!error) {
      setShowErrorMessage(false);
      fetch('/.netlify/functions/email', {
        method: 'POST',
        body: JSON.stringify({
          ...formValues,
          to: form.childFormsJson.formSubmitToEmail,
          form: form.childFormsJson.formTitle
        })
      })
      .then(res =>  console.log(res))
      setSubmitted(true);
    }
  }

  return (
    <section className={styles.section}>
      {onClose && <button className={styles.close} onClick={handleClose}>close</button>}
      
      {form && form.childFormsJson ? (
        <form onSubmit={handleSubmit} autoComplete={'false'}>
          <h1 className={styles.heading}>{form.childFormsJson.formHeading && form.childFormsJson.formHeading}</h1>
          {!submitted && <p className={styles.subHeading}>{form.childFormsJson.formSubheading && form.childFormsJson.formSubheading}</p>}
          {!submitted &&
          <div className={styles.page}>
            {form.childFormsJson.formPages.map((page, i) => (
              <div key={i} className={styles.formPage + ` ${currentPage === i ? `${styles.show}` : ''}`}>
                <div key={i} className={styles.fields}>
                  {page.formFields.map(field => (
                    <div key={field.label} className={styles.field + ` ${field.type === 'hidden' ? `${styles.hidden}` : ''}`}>
                      {field.type === 'text' ? (
                        <Text
                          styles={styles}
                          getStyle={getStyle}
                          field={field}
                          formValues={formValues}
                          handleInputChange={handleInputChange}
                        />
                      ) : field.type === 'textarea' ? (
                        <TextArea
                          styles={styles}
                          getStyle={getStyle}
                          field={field}
                          formValues={formValues}
                          handleInputChange={handleInputChange}
                        />
                      ) : field.type  === 'radio' ? (
                        <Radio
                          styles={styles}
                          getStyle={getStyle}
                          field={field}
                          formValues={formValues}
                          handleInputChange={handleInputChange}
                        />
                      ) : field.type === 'checkboxes' ? (
                        <CheckBoxes
                          styles={styles}
                          getStyle={getStyle}
                          field={field}
                          formValues={formValues}
                          handleInputChange={handleInputChange}
                        />
                      ) : field.type === 'dropdown' ? (
                        <Dropdown
                          styles={styles}
                          field={field}
                          formValues={formValues}
                          setFormValues={setFormValues}
                          handleInputChange={handleInputChange}
                          selectOpen={selectOpen}
                          setSelectOpen={setSelectOpen}
                          getStyle={getStyle}
                          handleOptionClick={handleOptionClick}
                        />
                      ) : field.type === 'slider' ? (
                        <Slider 
                          styles={styles}
                          getStyle={getStyle}
                          field={field}
                          formValues={formValues}
                          handleInputChange={handleInputChange}
                        />
                      ) : field.type === 'hidden' ? (
                        <input
                          type='hidden'
                          name={field.hidden.name}
                          value={field.hidden.value}
                        />
                      ) : null}
                    </div>
                  ))}
                </div>
                <div class={styles.buttonContainer}>
                  {showForm && form && form.childFormsJson && (currentPage > 0) && form.childFormsJson.formPages.length > 0 &&
                        <button className={styles.back} onClick={handleBack}>Back</button>
                      }
                  {(currentPage) === (form.childFormsJson.formPages.length - 1) &&
                      <button className={styles.submit}>Submit</button>}
                    {showForm && form && form.childFormsJson && form.childFormsJson.formPages.length > (currentPage + 1) && form.childFormsJson.formPages.length > 0 &&
                      <button className={styles.next} onClick={handleNext}>Next</button>
                    }
                    {showErrorMessage &&
                    <p className={styles.error}>Error: Missing or invalid inputs</p>}
                  </div>
              </div>
            ))}
          </div>}
          {submitted &&
      <ReactMarkdown className={styles.success}>{form.childFormsJson.responseText}</ReactMarkdown>}
        </form>
      ) : null}
   
    </section>
  )
}
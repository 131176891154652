// extracted by mini-css-extract-plugin
export var answer = "bF_gW";
export var arrow = "bF_9";
export var body = "bF_g";
export var category = "bF_dm";
export var contactIcons = "bF_gP";
export var current = "bF_fh";
export var fade = "bF_gS";
export var footer = "bF_cY";
export var footerText = "bF_cL";
export var icon = "bF_by";
export var iconContainer = "bF_b2";
export var issue = "bF_gV";
export var issueContainer = "bF_gR";
export var issueText = "bF_gT";
export var issues = "bF_gQ";
export var left = "bF_bV";
export var padding = "bF_dj";
export var right = "bF_bW";
export var section = "bF_b";
export var selectors = "bF_dD";
export var subtitle = "bF_cq";
export var title = "bF_f";
// extracted by mini-css-extract-plugin
export var bottomContainer = "K_cn";
export var buttons = "K_cp";
export var icon = "K_by";
export var link = "K_bv";
export var section = "K_b";
export var social = "K_cm";
export var text = "K_cl";
export var title = "K_f";
export var topContainer = "K_ck";
export var videoContainer = "K_ch";
export var videoWrapper = "K_cj";
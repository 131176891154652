import React from 'react'

export default ({ styles, field, getStyle , formValues, handleInputChange}) => (
  <>
    <p className={styles.textLabel}>{field.label}{field.required && ' *'}</p> 
    {field.options.map(option => (
      <div key={option} className={styles.checkboxOption}>
        <input 
          className={styles.checkboxInput + ` ${getStyle(field)}`} 
          type='checkbox' 
          id={option}
          value={option}
          name={field.label}
          checked={formValues[field.label][option]}
          onChange={handleInputChange}
        />
        <label className={styles.checkboxLabel} htmlFor={option}>{option}</label>
      </div>
    ))}
  </>
)
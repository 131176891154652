// extracted by mini-css-extract-plugin
export var background = "bJ_z";
export var backgroundContainer = "bJ_fm";
export var bottomWave = "bJ_c2";
export var buttons = "bJ_cp";
export var container = "bJ_h";
export var content = "bJ_b6";
export var icon = "bJ_by";
export var iconContainer = "bJ_b2";
export var middleWave = "bJ_c1";
export var negativeTop = "bJ_g2";
export var overlap = "bJ_g4";
export var section = "bJ_b";
export var selected = "bJ_bg";
export var tab = "bJ_dw";
export var tabText = "bJ_dv";
export var title = "bJ_f";
export var topWave = "bJ_g3";
// extracted by mini-css-extract-plugin
export var buttons = "Z_cp";
export var container = "Z_h";
export var content = "Z_b6";
export var icon = "Z_by";
export var iconContainer = "Z_b2";
export var image = "Z_d";
export var section = "Z_b";
export var selected = "Z_bg";
export var tab = "Z_dw";
export var tabText = "Z_dv";
// extracted by mini-css-extract-plugin
export var buttons = "bz_cp";
export var left = "bz_bV";
export var media = "bz_gk";
export var right = "bz_bW";
export var section = "bz_b";
export var selected = "bz_bg";
export var signature = "bz_gj";
export var testimonial = "bz_gh";
export var text = "bz_cl";
export var textContainer = "bz_b0";
export var video = "bz_fF";
// extracted by mini-css-extract-plugin
export var active = "bw_bd";
export var categories = "bw_dk";
export var category = "bw_dm";
export var icon = "bw_by";
export var icons = "bw_bw";
export var image = "bw_d";
export var member = "bw_fB";
export var memberTitle = "bw_fC";
export var name = "bw_fr";
export var placeholder = "bw_bb";
export var section = "bw_b";
export var show = "bw_L";
export var subtitle = "bw_cq";
export var team = "bw_fz";
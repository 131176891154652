// extracted by mini-css-extract-plugin
export var active = "T_bd";
export var arrow = "T_9";
export var border = "T_c7";
export var button = "T_b7";
export var column = "T_bt";
export var columns = "T_bs";
export var container = "T_h";
export var description = "T_c3";
export var dot = "T_dc";
export var footer = "T_cY";
export var footerText = "T_cL";
export var heading = "T_G";
export var icon = "T_by";
export var includes = "T_c9";
export var info = "T_cB";
export var left = "T_bV";
export var mobileOption = "T_db";
export var option = "T_c5";
export var optionText = "T_c6";
export var options = "T_c4";
export var padding = "T_dj";
export var plans = "T_c8";
export var popular = "T_dh";
export var price = "T_dd";
export var right = "T_bW";
export var section = "T_b";
export var show = "T_L";
export var subHeading = "T_H";
export var subText = "T_dg";
export var suffix = "T_df";
export var title = "T_f";
export var waveOne = "T_q";
export var waveThree = "T_cQ";
export var waveTwo = "T_n";
export var waves = "T_cP";
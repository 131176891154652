// extracted by mini-css-extract-plugin
export var container = "bG_h";
export var feature = "bG_gY";
export var featureText = "bG_gZ";
export var features = "bG_gX";
export var footer = "bG_cY";
export var footerText = "bG_cL";
export var icon = "bG_by";
export var section = "bG_b";
export var text = "bG_cl";
export var title = "bG_f";
export var waveOne = "bG_q";
export var waveThree = "bG_cQ";
export var waveTwo = "bG_n";
export var waves = "bG_cP";
// extracted by mini-css-extract-plugin
export var buttons = "P_cp";
export var column = "P_bt";
export var columns = "P_bs";
export var container = "P_h";
export var down = "P_cT";
export var download = "P_cS";
export var footer = "P_cY";
export var information = "P_cR";
export var section = "P_b";
export var show = "P_L";
export var text = "P_cl";
export var title = "P_f";
export var units = "P_cV";
export var up = "P_cX";
export var upload = "P_cW";
export var waveOne = "P_q";
export var waveThree = "P_cQ";
export var waveTwo = "P_n";
export var waves = "P_cP";
// extracted by mini-css-extract-plugin
export var caption = "bC_gG";
export var grey = "bC_dC";
export var heading = "bC_G";
export var image = "bC_d";
export var left = "bC_bV";
export var reverse = "bC_dy";
export var right = "bC_bW";
export var section = "bC_b";
export var video = "bC_fF";
export var videoContainer = "bC_ch";
export var videoWrapper = "bC_cj";
// extracted by mini-css-extract-plugin
export var body = "bs_g";
export var buttons = "bs_cp";
export var container = "bs_h";
export var icon = "bs_by";
export var icons = "bs_bw";
export var image = "bs_d";
export var items = "bs_fs";
export var label = "bs_cs";
export var mobileButtons = "bs_ft";
export var name = "bs_fr";
export var section = "bs_b";
export var selected = "bs_bg";
export var show = "bs_L";
export var title = "bs_f";
import React from 'react'

export default ({ styles, field, getStyle, formValues, handleInputChange }) => (
  <>
    <label className={styles.textLabel} htmlFor={field.label}>{field.label}{field.required && '*'}</label>
    <input
      type={'text'}
      className={styles.textInput + ` ${getStyle(field)}`} 
      id={field.label} 
      name={field.label} 
      value={formValues[field.label]}
      onChange={handleInputChange}
      placeholder={field.placeholder}
    />
  </>
)
// extracted by mini-css-extract-plugin
export var buttons = "bp_cp";
export var container = "bp_h";
export var image = "bp_d";
export var innerText = "bp_fk";
export var logo = "bp_bn";
export var p = "bp_fc";
export var section = "bp_b";
export var short = "bp_fd";
export var tall = "bp_fl";
export var textContainer = "bp_b0";